.container {
  margin-top: 24px;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-accent);
  }
  .active {
    background: var(--color-primary);
  }
}
