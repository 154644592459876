.masterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  h2 {
    margin: 32px 0 8px 0;
  }
  .subtext {
    margin: 0 32px;
  }
}

.settingsContainer {
  width: 100%;
}
