.masterContainer {
  z-index: 100;
}
.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--color-background);
  display: flex;
  justify-content: center;
  align-items: center;
}
